<template>
    <div class="Box" v-loading.fullscreen.lock='loading' element-loading-text="拼命加载中" element-loading-background="rgba(255, 255, 255, 0.5)">
      <div>
        <el-select v-model="pageInfo.storageId" clearable style="width:140px;margin-right:10px" placeholder="全部仓库" @change="find">
            <el-option
            v-for="item in storageList"
            :key="item.id"
            :label="item.value" 
            :value="item.id">
            </el-option>
        </el-select>
        <el-select v-model="pageInfo.status" clearable style="width:140px;margin-right:10px" placeholder="全部状态" @change="find">
            <el-option
            v-for="item in productStatusList"
            :key="item.id"
            :label="item.value" 
            :value="item.id">
            </el-option>
        </el-select>
        <el-input v-model="pageInfo.keyword" style="width:260px;margin-right:10px" placeholder="搜索内容" @keyup.enter.native = "find">
          <el-select v-model="pageInfo.searchType" style="width:100px" slot="prepend">
            <el-option
            v-for="item in detailSearchType"
            :key="item.id"
            :label="item.value"
            :value="item.id"></el-option>
          </el-select>
        </el-input>
        <el-button type='primary' @click="find">查询</el-button><br>
        <el-button style="margin-top:5px" @click="openInventoryImportant">库存导入</el-button>
        <TableDialog style="float:right;margin-top:10px" :tableList='headList' @setTableHead='gethead'></TableDialog>
      </div>
      <div class="content" style="flex:1;overflow:auto;padding-top:15px">
        <el-table
          height="100%"
          :data="tableData"
          @sort-change="sortTable"
          style="width: 100%"
          :cell-style="{ fontSize: '13px', padding: '7px 0' }"
          :header-cell-style="{
              fontSize: '13px',
              padding: '9px 0',
              marginTop: '10px',
              background: '#EBEEF5',
          }">
          <el-table-column
          type="selection"
          width="55">
          </el-table-column>
          <el-table-column
            prop="prop"
            label="商品信息"
            width="250"
            fixed="left">
            <template slot-scope="scope">
                <div style="display:flex;align-items:center">
                    <Picture :url='scope.row.imageUrl' width='50' style="margin-right:15px"></Picture>
                    <!-- 字 -->
                    <div style="width:150px">
                        <p class="infoText" style="text-align:left">{{scope.row.title}}</p>
                    </div>
                </div>
            </template>
          </el-table-column>
          <el-table-column
              v-for="item in head"
              :sortable="item.sortable?'custom':false"
              :key="item.name"
              :prop="item.prop"
              :label="item.name"
              :min-width="item.width">
              <template slot-scope="scope">
                <div v-if="item.name=='品名/SKU'">
                  <p :title="scope.row.title" style="width:100px" class="oneline">{{scope.row.title}}</p>
                  <p :title="scope.row.sku" class="oneline">{{scope.row.sku}}</p>
                </div>
                <!-- <div v-if="item.name=='可用锁定量'">
                  <span>{{scope.row.goodLockQuantity}}<i class="el-icon-arrow-down"></i></span>
                </div> -->
                <div v-else-if="item.name=='待检量'">
                  <span>{{scope.row.toCheckQuantity}}</span>
                  <el-popover
                    placement="right"
                    trigger="click">
                      <el-table 
                      :data="scope.row.toCheckDtoList"
                      :cell-style="{ fontSize: '13px', padding: '7px 0' }"
                      :header-cell-style="{
                          fontSize: '13px',
                          padding: '9px 0',
                          marginTop: '10px',
                          background: '#EBEEF5',
                      }">
                        <el-table-column width="150" property="purchaseNo" label="到货记录"></el-table-column>
                        <el-table-column width="150" property="crNo" label="采购单号"></el-table-column>
                        <el-table-column width="80" property="checkingQuantity" label="质检中"></el-table-column>
                        <el-table-column width="80" property="toCheckQuantity" label="未质检"></el-table-column>
                        <el-table-column width="100" property="createByName" label="创建人"></el-table-column>
                      </el-table>
                      <i slot="reference" class="el-icon-arrow-down" v-if="scope.row.toCheckQuantity>0"></i>
                  </el-popover>
                </div>
                <div v-else-if="item.name=='待到货量'">
                  {{scope.row.toPutNumber}}
                  <el-popover
                    placement="right"
                    trigger="click">
                      <el-table 
                      :data="scope.row.toArriveDtoList"
                      :cell-style="{ fontSize: '13px', padding: '7px 0' }"
                      :header-cell-style="{
                          fontSize: '13px',
                          padding: '9px 0',
                          marginTop: '10px',
                          background: '#EBEEF5',
                      }">
                        <el-table-column width="150" property="purchaseNo" label="采购单号"></el-table-column>
                        <el-table-column width="80" property="toPutNumber" label="数量"></el-table-column>
                        <el-table-column width="150" property="expectArrivalTime" label="预计到货时间"></el-table-column>
                        <el-table-column width="150" property="createByName" label="创建人"></el-table-column>
                      </el-table>
                      <i slot="reference" class="el-icon-arrow-down" v-if="scope.row.toArriveDtoList.length>0"></i>
                  </el-popover>
                </div>
                <div v-else-if="item.name=='可用量'||item.name=='可用锁定量'||item.name=='次品量'||item.name=='次品锁定量'">
                  {{scope.row[item.prop]}}
                  <el-popover
                    placement="right"
                    trigger="click">
                      <el-table 
                      :data="scope.row.detailQuantityDtoList"
                      :cell-style="{ fontSize: '13px', padding: '7px 0' }"
                      :header-cell-style="{
                          fontSize: '13px',
                          padding: '9px 0',
                          marginTop: '10px',
                          background: '#EBEEF5',
                      }">
                        <el-table-column width="130" property="noteNo" label="单号"></el-table-column>
                        <el-table-column width="80" property="goodQuantity" label="良品量"></el-table-column>
                        <el-table-column width="100" property="goodLockQuantity" label="良品锁定量"></el-table-column>
                        <el-table-column width="80" property="badQuantity" label="次品量"></el-table-column>
                        <el-table-column width="100" property="badLockQuantity" label="次品锁定量"></el-table-column>
                        <el-table-column width="150" property="createByName" label="创建人"></el-table-column>
                        <el-table-column width="150" property="createTime" label="创建时间"></el-table-column>
                      </el-table>
                      <i slot="reference" class="el-icon-arrow-down" v-if="scope.row[item.prop]>0"></i>
                  </el-popover>
                </div>
                <div v-else>{{scope.row[item.prop]}}</div>
              </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="text-align:center;padding:19px">
          <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageInfo.current"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="10"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
          </el-pagination>
      </div>
      <el-dialog
        title="修改库存"
        :visible.sync="updateDialog"
        width="550px"
        :before-close="updateDialogClose">
        <div style="margin-top:-20px">
          <el-select v-model="updateInfo.stockType" style="width:90px">
            <el-option
              v-for="item in stockTypeList"
              :key="item.id"
              :label="item.value"
              @click.native="change(item)"
              :value="item.id">
            </el-option>
          </el-select>
          <el-form ref="addInfo" :model="addInfo" label-width="80px" style="width:300px;margin:30px auto 0">
            <el-form-item label="仓库">
              <el-input disabled v-model="info.storageName" placeholder="请输入仓库"></el-input>
            </el-form-item>
            <el-form-item label="SKU">
              <el-input disabled v-model="info.sku" placeholder="请输入SKU"></el-input>
            </el-form-item>
            <el-form-item label="现存">
              <el-input disabled v-model="number" placeholder="请输入现存数量"></el-input>
            </el-form-item>
            <el-form-item label="修改">
              <el-select v-model="updateInfo.operateType" style="width:80px;margin-right:10px">
                <el-option
                  v-for="item in operateTypeList"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id">
                </el-option>
              </el-select>
              <el-input v-model="updateInfo.number" type="number" style="width:130px" placeholder=""></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div slot="footer">
          <el-button @click="updateDialogClose">取 消</el-button>
          <el-button type="primary" @click="sure" :loading='buttonLoading'>确 定</el-button>
        </div>
      </el-dialog>
      <el-dialog
          title="库存导入"
          :visible.sync="inventoryImportant"
          width="25%"
          :before-close="inventoryImportantClose">
          <div style="margin-top:-20px;line-height:20px;font-size:12px">
              <p>1.模板中的表头不可更改，不可删除</p>
              <p>2.导入前请在仓库列表中添加所属的仓库</p>
              <p>3.导入前请在商品列表中添加相应的商品</p>
              <p>4.若SKU已有库存信息，则不会处理该条数据</p>
              <el-button type="primary" @click="down" plain style="width:100%;margin:15px 0">下载空白模板<i class="el-icon-download"></i></el-button>
              <div style="text-align:center">
                  <el-upload
                  style="width:100%"
                  class="upload-demo"
                  :headers="mytoken"
                  :on-success="handleSuccess"
                  :file-list="fileList"
                  drag
                  :action="action"
                  multiple>
                      <i class="el-icon-upload"></i>
                      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                  </el-upload>
              </div>
          </div>
          <div slot="footer">
              <el-button @click="inventoryImportantClose">取 消</el-button>
              <el-button type="primary" @click="inventoryImportantClose">确 定</el-button>
          </div>
      </el-dialog>
  </div>
</template>

<script>
import {
  listPage, 
  pageInit, 
  delGoods, 
  updateInit, 
  updateLoaclstock,
  headList
} from '@/api/InventoryManage/inventory.js'
import TableDialog from '@/components/tableDialog'
export default {
  components:{
    TableDialog,
  },
  data(){
    var mytoken=window.sessionStorage.getItem('token')
    return {
      loading:false,
      updateDialog:false,
      buttonLoading:false,
      inventoryImportant:false,
      mytoken:{token:mytoken},
      tableData:[],
      pageInfo:{
        current:'',
        pageSize:'10',
        keyword:'',
        searchType:'sku',
        status:'',
        sort:'',
        storageId:'',
        sortColumn:'',
      },
      total:'',
      localStorageTypeList:[],
      operateTypeList:[],
      stockTypeList:[],
      updateInfo:{
        id:'',
        number:'0',
        operateType:'add',
        stockType:'good',
      },
      info:{
        storageName:'',
        sku:'',
        badNumber:'',
        goodNumber:'',
      },
      number:'',
      fileList:[],
      headList:[],
      head:[],
      detailSearchType:[],
      productStatusList:[],
      shopList:[],
      storageList:[]
    };
  },
  created(){
    this.getPageInit()
    this.getListPage()
    this.headList=headList
    this.head = this.headList
  },
  computed:{
      action(){
          return process.env.VUE_APP_API_URL+'/api/loaclstock/addStockFile';
      }
  },
  methods:{
    inventoryImportantClose(){
        this.inventoryImportant=false
        this.fileList=[]
        this.getListPage()
    },
    handleSuccess(res, file, fileList){
      console.log(res);
        console.log(res, file, fileList);
        if(res.code==200){
            this.$message.success(res.data.message)
            this.fileList=fileList
            if(res.data.code==300){
              let str = `<div style="max-height:200px;overflow:auto;padding-left:20px">`
                res.data.data.forEach(item=>{
                    str+= `<p style="line-height:24px">${item}</p>`
                })
                str+=`</div>`
                this.$alert(str, '库存导入失败项', {
                    dangerouslyUseHTMLString: true
                });
            }
        }else{
            this.$message.error(res.message)
            this.fileList=fileList.filter(item=>{
                return item.response.code==200;
            })
        }
    },
    down(){
      window.location.href='库存初始值设置模板.xlsx'
    },
    openInventoryImportant(){
      this.inventoryImportant=true
    },
    updateDialogClose(){
      this.updateInfo={
        id:'',
        number:'0',
        operateType:'add',
        stockType:'good',
      }
      this.updateDialog=false
    },
    sure(){
      this.buttonLoading=true
      updateLoaclstock(this.updateInfo).then(res=>{
        this.buttonLoading=false
        if(res.data.code==200){
            this.$message.success(res.data.message)
            this.updateDialogClose()
            this.getListPage()
        }else {
            this.$message.error(res.data.message)
        }
      }).catch(error=>{
        this.buttonLoading=false
      })
    },
    change(item){
      if(item.id=='good'){
        this.number=this.info.goodNumber
      }else{
        this.number=this.info.badNumber
      }
    },
    reset(){
        this.pageInfo={
          current:'',
          pageSize:'10',
          storageType:'',
          keyword:''
        }
        this.getListPage()
    },
    update(id){
      this.updateInfo.id=id
      this.loading=true
      updateInit(id).then(res=>{
        this.loading=false
        this.updateDialog=true
        console.log(res);
        this.operateTypeList=res.data.data.operateTypeList
        this.stockTypeList=res.data.data.stockTypeList
        this.info=res.data.data.LocalStock
        this.number=this.info.goodNumber
      })
    },
    delItem(id){
        this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            this.loading=true
            delGoods(id).then(res=>{
                this.loading=false
                if(res.data.code==200){
                    this.$message.success(res.data.message)
                    this.getListPage()
                }else {
                    this.$message.erro(res.data.message)
                }
            }).catch(()=>{
                this.loading=false
            })
        }).catch(() => {
            this.$message({
                type: 'info',
                message: '已取消删除'
            });          
        });
    },
    getAddInit(){
        addInit().then(res=>{
          console.log(res);
        })
    },
    getPageInit(){
        pageInit().then(res=>{
          this.detailSearchType=res.data.data.detailSearchType
          this.productStatusList=res.data.data.productStatusList
          this.shopList=res.data.data.shopList
          this.storageList=res.data.data.storageList
        })
    },
    handleSizeChange(val){
        this.pageInfo.pageSize=val
        this.getListPage()
    },
    handleCurrentChange(val){
        this.pageInfo.current=val
        this.getListPage()
    },
    find(){
        this.pageInfo.current=1
        this.getListPage()
    },
    gethead(val){
      this.head=val
    },
    sortTable(column){
        if(column.order=='ascending'){
            this.pageInfo.sort='asc'
        }else if(column.order=='descending'){
            this.pageInfo.sort='desc'
        }else if(column.order==null){
            this.pageInfo.sort=''
            this.pageInfo.sortColumn=''
            this.getListPage()
            return
        }
        this.pageInfo.sortColumn=column.prop
        this.getListPage()
    },
    getListPage(){
      this.loading=true
      listPage(this.pageInfo).then(res=>{
        this.loading=false
        this.tableData=res.data.values
        this.total=res.data.pageInfo.total
      }).catch(()=>{
        this.loading=false
      })
    }
  }
}
</script>

<style scoped lang='scss'>
.infoText{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 25px;
}
::v-deep .el-upload{
    width: 100%;
    .el-upload-dragger{
        width: 100%;
    }
}
::v-deep .el-upload-list__item-name{
        text-align: left;
    }
.Box{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 67px);
}
.oneline{
    white-space: nowrap;  
    text-overflow:ellipsis;
    overflow:hidden;
}
::v-deep .el-input .el-input-group__prepend {
  background-color: #fff;
}
</style>