import request from '@/utils/request'

export const headList = [
    {name:'品名/SKU',prop:'',width:120},
    {name:'仓库',prop:'storageName',width:120},
    {name:'品牌',prop:'',width:120},
    {name:'Listing负责人',prop:'',width:120},
    {name:'可用量',prop:'goodQuantity',width:120,sortable:true},
    {name:'可用锁定量',prop:'goodLockQuantity',width:120,sortable:true},
    {name:'次品量',prop:'badQuantity',width:120,sortable:true},
    {name:'次品锁定量',prop:'badLockQuantity',width:120,sortable:true},
    {name:'待检量',prop:'toCheckQuantity',width:120,sortable:true},
    {name:'待到货量',prop:'toPutNumber',width:120,sortable:true},
    {name:'调拨在途',prop:'',width:120,sortable:true},
    {name:'调拨在途库存成本',prop:'',width:150,sortable:true},
    {name:'实际总量',prop:'actualTotal',width:120,sortable:true},
    {name:'预计总量',prop:'estimatedTotal',width:120,sortable:true},
    {name:'采购单价',prop:'purchaseUnitPrice',width:120,sortable:true},
    {name:'单位费用',prop:'unitCost',width:120,sortable:true},
    {name:'单位库存成本',prop:'warehouseUnitCost',width:150,sortable:true},
    {name:'货值',prop:'value',width:120,sortable:true},
    {name:'费用',prop:'cost',width:120,sortable:true},
    {name:'库存成本',prop:'warehouseCost',width:120,sortable:true},
    {name:'期望可用量',prop:'',width:120},
    {name:'期望待到货量',prop:'',width:120},
    {name:'调拨在途费用',prop:'',width:120},
    {name:'调拨在途货值',prop:'',width:120},
]

export function listPage(params) {
    return request({
        url:'/api/warehouseDetail/listPage',
        method:'get',
        params
    });
}

export function pageInit() {
    return request({
        url:'/api/warehouseDetail/pageInit',
        method:'get',
    });
}

export function delGoods(id) {
    return request({
        url:`/api/loaclstock/${id}`,
        method:'delete',
    });
}

export function updateInit(id) {
    return request({
        url:`/api/loaclstock/updateInit/${id}`,
        method:'get',
    });
}

export function updateLoaclstock(data) {
    return request({
        url:`/api/loaclstock`,
        method:'put',
        data
    });
}

